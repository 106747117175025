import { useEffect } from "react"
import { useTranslation } from "next-i18next"
import { useContactForm } from "./useContactForm"
import Loader from "@/shared/components/Loader"
import styles from "./ContactForm.module.scss"
import ContactLink from "@/shared/components/ContactLink"
import StrongTextParser from "@/shared/components/StrongTextParser"
import TextInput from "@/shared/components/TextInput"
import Button from "@/shared/components/Button"
import { ContactFormProps } from "./types"

const ContactForm = ({
  setSubmitSuccess,
  onlyMandatoryFields,
  submitButtonClassName = "",
  submitButtonText,
  textWhite,
}: ContactFormProps) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    submitLoading,
    submitSuccess,
    submitError,
    requestLimitReached,
  } = useContactForm()
  const { t } = useTranslation("common")

  useEffect(() => {
    if (setSubmitSuccess) {
      setSubmitSuccess(submitSuccess)
    }
  }, [submitSuccess, setSubmitSuccess])

  return submitSuccess && !requestLimitReached ? (
    <p className={styles.successText}>
      <StrongTextParser text={t("contactForm.success")} />
    </p>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.fieldWrapper}>
        <TextInput
          label={t("contactForm.labels.name")}
          required
          errorMessage={errors.name?.message}
          {...register("name")}
          textWhite={textWhite}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <TextInput
          label={t("contactForm.labels.email")}
          required
          errorMessage={errors.email?.message}
          type="email"
          {...register("email")}
          textWhite={textWhite}
        />
      </div>

      {!onlyMandatoryFields && (
        <>
          <div className={styles.fieldWrapper}>
            <TextInput
              label={t("contactForm.labels.phone")}
              errorMessage={errors.phone?.message}
              type="tel"
              {...register("phone")}
              textWhite={textWhite}
            />
          </div>

          <div className={styles.fieldWrapper}>
            <TextInput
              label={t("contactForm.labels.helpRequest")}
              isTextArea
              errorMessage={errors.helpRequest?.message}
              {...register("helpRequest")}
              textWhite={textWhite}
            />
          </div>
        </>
      )}

      {submitError && !requestLimitReached && (
        <p className={styles.errorText}>
          {t("contactForm.error")}&nbsp;
          <ContactLink />
        </p>
      )}
      {requestLimitReached && (
        <p className={styles.errorText}>
          {t("contactForm.requestLimitReached")}&nbsp;
          <ContactLink />
        </p>
      )}

      {submitLoading ? (
        <div className={styles.loaderWrapper}>
          <Loader dimensions={30} />
        </div>
      ) : (
        <Button
          type="submit"
          text={submitButtonText || t("contactForm.submit")}
          className={`${styles.submitButton} ${submitButtonClassName}`}
          disabled={submitLoading || requestLimitReached}
        />
      )}
    </form>
  )
}

export default ContactForm
