import { useTranslation } from "next-i18next"
import styles from "./ContactSection.module.scss"
import Container from "@/shared/components/Container"
import ContactForm from "@/shared/components/ContactForm"
import { useState } from "react"

const ContactSection = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const { t } = useTranslation("common")

  return (
    <Container>
      <section
        id="contact"
        className={`${styles.section} ${
          submitSuccess ? styles.formSubmitSuccess : ""
        }`}
      >
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>{t("contactForm.title")}</h2>
          <p>{t("contactForm.subtitle")}</p>
        </div>

        <div className={styles.formWrapper}>
          <ContactForm
            setSubmitSuccess={setSubmitSuccess}
            submitButtonClassName={styles.submitButton}
          />
        </div>
      </section>
    </Container>
  )
}

export default ContactSection
