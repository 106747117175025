import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "next-i18next"
import { ContactFormValues } from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { useMutation } from "react-query"
import * as Sentry from "@sentry/nextjs"

export const useContactForm = () => {
  const { t } = useTranslation()
  const [requestLimitReached, setRequestLimitReached] = useState(false)

  const validationSchema = useMemo(
    () =>
      z.object({
        name: z
          .string()
          .min(2, { message: t("validation.required") as string }),
        email: z
          .string()
          .email({ message: t("validation.required") as string }),
        phone: z.union([
          z
            .string()
            .min(6, { message: t("validation.phone") as string })
            .regex(/^[\d +-]+$/, { message: t("validation.phone") as string }),
          z.string().length(0),
        ]),
        helpRequest: z.union([
          z
            .string()
            .trim()
            .min(2, {
              message: t("validation.minLength", { characters: 2 }) as string,
            }),
          z.string().length(0),
        ]),
      }),
    [t]
  )

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      lastName: "",
      brandName: "",
      businessName: "",
      email: "",
      phone: "",
      currentlyOnAmazon: "yes",
      helpRequest: "",
    },
  })

  const { mutate, isError, isSuccess, isLoading, error } = useMutation(
    async (values: ContactFormValues) => {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_PORT}/api/contact-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      )

      if (response.status === 429) {
        setRequestLimitReached(true)
      }

      if (!response.ok) {
        Sentry.captureException(response.statusText)
        throw new Error(response.statusText)
      }

      return { data: response.json() }
    }
  )

  const onSubmit = (values: ContactFormValues) => mutate(values)

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    isSubmitting,
    control,
    submitError: isError ? (error as Error).message : null,
    submitSuccess: isSuccess,
    submitLoading: isLoading,
    requestLimitReached,
  }
}
