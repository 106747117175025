import { forwardRef, LegacyRef } from "react"
import { TextInputProps } from "./types"
import styles from "./TextInput.module.scss"

const TextInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextInputProps
>(
  (
    {
      label,
      disabled,
      className,
      errorMessage,
      name,
      required,
      isTextArea,
      textWhite,
      type = "text",
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`${className} ${errorMessage ? styles.error : ""} ${
          textWhite ? styles.white : ""
        }`}
      >
        <label className={styles.labelWrapper}>
          <span className={styles.labelText}>
            {label}
            <span className={styles.requiredSign}>{required ? "*" : ""}</span>
          </span>
          {isTextArea ? (
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              id={name}
              name={name}
              disabled={disabled}
              ref={ref as unknown as LegacyRef<HTMLTextAreaElement>}
              {...rest}
            />
          ) : (
            <input
              className={styles.input}
              id={name}
              name={name}
              disabled={disabled}
              ref={ref as unknown as LegacyRef<HTMLInputElement>}
              type={type}
              {...rest}
            />
          )}
        </label>
        {errorMessage && (
          <span className={styles.errorText}>{errorMessage}</span>
        )}
      </div>
    )
  }
)

TextInput.displayName = "TextInput"

export default TextInput
